<template>
  <div id="init" v-loading="loading" element-loading-text="登录中..."  element-loading-background="rgba(173 ,216, 230, 0.8)"></div>
</template>
<script setup>
  import {
		getCurrentInstance,
		ref,
		reactive,
		onMounted
	} from 'vue'
  // 访问 globalProperties
	const ctx = getCurrentInstance().appContext.config.globalProperties

  const loading = ref(true)
  onMounted(() => {
    if (!ctx.$route.query.code || !ctx.$route.query.state) {
      ctx.$router.push("/sign_account");
    } else {
      getUserInfoByqy();
    }
  }) 
  const getUserInfoByqy = () => {
    const params = {
      code: ctx.$route.query.code,
      state: ctx.$route.query.state
    };
    ctx.$request_nl_.post(ctx.$api_.state.Frame.Login.auth_login.url, params).then(res => {
      if (res.Code === 200) {
        ctx.$message({
          message: res.Message,
          type: 'success'
        })
        setTimeout(() => {
          ctx.$router.push({
            path: '/'
          })
        }, 1500)
      } else {
        let msg = res.Message ? res.Message : "获取用户信息失败！";
        ctx.$message({
          message: msg,
          type: 'error'
        })
        ctx.$router.push("/sign_account");
      }
    })
  }
</script>

<style lang="less" scoped>
  #init {
    width: 100vw;
    min-height: calc(100vh - 60px);
    display: flex;

    .right-wp {
      flex: 1;
      width: calc(100vw - 150px);
      // background-color: var(--theme-bg-color);
    }

    .right-wp.expand {
      width: calc(100vw - 250px);
    }
  }
</style>
